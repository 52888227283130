<template>
  <div class="home">
    <div class="card">
      <div class="title">平台信息统计</div>
      <ul class="number">
        <li>
          <p>试用企业数量 </p>
          <h2>188888</h2>
        </li>
        <li>
          <p>付费企业数量</p>
          <h2>188888</h2>
        </li>
        <li>
          <p>平台使用人数</p>
          <h2>188888</h2>
        </li>
        <li>
          <p>奖扣事件数</p>
          <h2>188888</h2>
        </li>
      </ul>
    </div>

    <div class="card">
      <div class="title">近七日企业用户新增走势</div>
      <div class="echarts" id="echarts1">

      </div>
    </div>
    <div class="card">
      <div class="title">近七日奖扣事件新增走势</div>
      <div class="echarts" id="echarts2">

      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  mounted() {
    this.initEcharts("echarts1");
    this.initEcharts("echarts2");
  },
  methods: {
    initEcharts(id) {
      var myChart = echarts.init(document.getElementById(id));
      // 绘制图表
      myChart.setOption({
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["1", "2", "3", "4", "5", "6", "7"],
        },
        grid: {
          left: "50px",
          right: "20px",
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [820, 32, 901, 93, 1290, 130, 1320],
            type: "line",
            smooth: true,
            showSymbol: false,
            itemStyle: {
              color: "#0770FF",
            },
            
             
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(55, 162, 255)",
                },
                {
                  offset: 1,
                  color: "rgba(116, 21, 219)",
                },
              ]),
            },
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss"  scoped>
.home {
  line-height: 1.5;
  .card {
    // border: 1px solid #eee;
    padding: 0 20px 20px 20px;
    .title {
      line-height: 45px;
      height: 45px;
      color: #676767;
      font-size: 18px;
      font-weight: 600;
    }
    .number {
      display: flex;
      height: 160px;
      > li {
        flex: 1;
        margin-right: 30px;
        color: #fff;
        border-radius: 5px;
        text-align: center;
        p {
          font-size: 22px;
          margin-top: 40px;
        }
        h2 {
          line-height: 60px;
          font-size: 30px;
        }
        &:nth-child(1) {
          background: orange;
        }
        &:nth-child(2) {
          background: rgb(50, 145, 248);
        }
        &:nth-child(3) {
          background: rgb(255, 37, 37);
        }
        &:nth-child(4) {
          background: rgb(215, 173, 3);
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .echarts {
      height: 350px;
    }
  }
}
</style>

